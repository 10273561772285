import { useTranslation } from "react-i18next";

export default function LanguageSelector(props){

    const languages = [
        {
            name: 'English',
            value: 'en'
        },{
            name: 'ภาษาไทย',
            value: 'th'
        },
        {
            name: '中文',
            value: 'zh'
        }
    ];

    const {t, i18n} = useTranslation();

    const language = i18n.language;

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }

    return (
        <>
            <select name="language" className={`border-0 rounded-lg select ${props.className}`} id="language-selector" onChange={(event) => changeLanguage(event.target.value)} value={language}>
            {languages.map(lang => (
                <option key={lang.value} value={lang.value}>{lang.name}</option>
            ))}
            </select>
        </>
    )
}