import logo from './logo.svg';

import {useTranslation} from "react-i18next";
import Card from './components/Card';
import LanguageSelector from './components/LanguageSelector';


function App() {

  const {t} = useTranslation();

  return (
    <div className="w-full min-h-screen flex items-center">
      <div className="container block h-fit mx-auto">
        <div className="w-full h-12">
          <LanguageSelector className="float-right" />
        </div>        
        
        <div>
          <h1 className="text-center text-3xl font-extrabold text-transparent bg-gradient-to-r bg-clip-text from-violet-500 to-fuchsia-700">{t('header.title')}</h1>

          <div className="px-6 py-3 mt-5 text-center">
          {Object.values(t('header.introduction', {returnObjects: true})).map((p, index) => (
            <p key={index} className="my-3">{p}</p>
          ))}  
          </div>    

          <div className="flex flex-wrap justify-center gap-6 mb-16">
            <Card img={t('cards.retail.image')} title={t('cards.retail.title')} desc={t('cards.retail.description')} cta={t('cards.retail.cta')} cta_url={t('cards.retail.cta_link')}></Card>
            <Card img={t('cards.wholesale.image')} title={t('cards.wholesale.title')} desc={t('cards.wholesale.description')} cta={t('cards.wholesale.cta')} cta_url={t('cards.wholesale.cta_link')}></Card>
            <Card img={t('cards.supplies.image')} title={t('cards.supplies.title')} desc={t('cards.supplies.description')} cta={t('cards.supplies.cta')} cta_url={t('cards.supplies.cta_link')}></Card>
          </div>                
        </div>
      </div>
    </div>
  );
}

export default App;
