export default function Card(props) {

  const handleClick = () => {
    if(props.cta_url){
      try{
        window.open(props.cta_url, '_blank').focus();
      }catch(e){
        window.location = props.cta_url;
      }
    }    
  }

  return (
    <div className="card w-96 bg-base-100 shadow-2xl hover:bg-base-200 hover:cursor-pointer" onClick={handleClick}>
      <figure className="px-3 pt-3">
        <img
          src={props.img}
          alt="Shoes"
          className="rounded-xl"
        />
      </figure>
      <div className="card-body items-center text-center">
        <h2 className="card-title">{props.title}</h2>
        <p>{props.desc}</p>
        <div className="card-actions">
          <button className="btn btn-primary">{props.cta}</button>
        </div>
      </div>
    </div>
  );
}
